<template>
  <div id="custom">
    <vue-headful
            title="Custom Timing - IB English Oral Helper"
    />
    <!-- <Navbar></Navbar> -->

    <div id="section1" class="section1 preTimerC" style="padding-bottom: 25% !important;">

      <div class="container content fade-in" style="margin-bottom: 15% !important;">
        <h1 id="title" class="title heading">
          Customize Timing
        </h1>
        <h1 id="timer" style="display:none;" class="title heading">
          <label id="minutes">00</label>:<label id="seconds">00</label>
        </h1>
        <div id="wrap" class="wrap">
        <h2 id="state" class="subtitle">Follow your own structure that works for you.</h2>
        <h2 style="display:none;" id="state2" class="subtitle">
          Visual cues will show above.<br>
          When you're ready, hit the button.
        </h2>
        <button style="display:none;" onClick="actualStart()" type="button" class="btn btn-better" id="actualStart">Let's begin!</button>

        </div>
          <div class="row mt-4" id="structure">
            <div class="col-sm">
              <p>
                <span id="msg">
                  Please configure the options below.
                  <br><br>
                  Remember that you have 10 minutes to do the oral, so regardless of what timing you set, you must not go over 10 minutes as that's when your part of the oral stops and switches over to the Q&amp;A session.
                </span>
                <span id="tip"><br><br><strong style="text-decoration:underline;">All timing settings must be in seconds</strong>!</span>
              </p>

            <div id="customTimingConfig">
                <div class="form-group text-white">
                    <label for="part1">Start of your first discussion part</label>
                    <input type="text" class="form-control" id="part1" value="60" required>
                </div>

                <div class="form-group text-white">
                    <label for="part2">Start of your second discussion part</label>
                    <input type="text" class="form-control" id="part2" value="300" required>
                </div>

                <div class="form-group text-white">
                    <label for="pre-concl">Wrap-up notice about conclusion</label>
                    <input type="text" class="form-control" id="pre-concl" value="526" required>
                </div>
                
                <div class="form-group text-white">
                    <label for="concl">Start of conclusion</label>
                    <input type="text" class="form-control" id="concl" value="540" required>
                </div>
            </div>

              <button onClick="startOral()" type="button" class="btn btn-better" id="startOral">Start my practice oral!</button>
              <button style="display:none;" onClick="endOral()" type="button" class="btn btn-better" id="endOral">End oral practice</button>
              
            </div>

            <div class="col-sm"></div>
          </div>
          <!-- <a href="#" target="_blank">
            <i class="fab fa-facebook fa-3x" style="color:white;"></i>
          </a> -->

      </div>

    </div>

    <!-- <div id="arrow" class="arrow bounce">
      <a class="fa fa-arrow-down fa-2x" href="#"></a>
    </div> -->

    


  <Footer></Footer>

  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style>
.content{
  padding-top:2%;
}
.title{
  font-size: 50px;
}
.subtitle{
  font-size: 25px;
}
.highlight-better{
  background-color: #b64a79;
  color: rgb(255, 255, 255) !important;
  padding: 10px 10px 10px 10px !important;
  /* max-width: 500px; */
  transition: 0.4s;
  font-size: 30px;
}

#state{
  transition: 0.4s;
  padding: 10px 10px 10px 0px;
  border-radius: 0.5rem;
  width: fit-content;
}

.btn-better {
  background: #b64a79;
  color: white;
  border: #000 solid 1px;
  padding: 8px 15px 8px 15px;
  border-radius: 0.2rem;
  transition: 0.2s;
}
.btn-better:hover {
  opacity: 0.7;
  color: white;
  background-color: rgba(84, 89, 95, 1);
  border-color: #54595f;
  text-decoration: none;
  cursor: pointer;
}
.btn-better:focus {
  opacity: 0.7;
  color: #ffffff;
  background-color: #a06464 !important;
  border-color: #000;
  box-shadow: 0 0 0 0.2rem #54595f;
  text-decoration: none;
  cursor: pointer;
}
.devider{
  margin-top: 2%;
  margin-bottom: 2%;
}


.heading-2{
  font-size: 105px !important;
  transition: 1s;
}

@media (min-width: 600px) {
  .heading-2{
    font-size: 140px !important;
    transition: 1s;
  }
}

.preTimerC{
  padding-top:2% !important;
  margin-bottom: 5% !important;
}
.postTimerC{
  padding-top:15% !important;
  transition: 0.8s;
}

@media (min-width: 600px) {
  .preTimerC{
    padding-top:3% !important;
  }
  .postTimerC{
    padding-top:7% !important;
    transition: 0.8s;
  }
}


.blur{
  filter: blur(10px);
  transition: 0.5s;
}

@media (min-width: 600px) {
  .blur{
    filter: blur(15px);
    transition: 0.5s;
  }
}

</style>
